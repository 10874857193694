<template lang="pug">
    Section(title="Update lesson content", subtitle="Use this admin panel to update any given lesson, one by one.")
        h4 Content comes from /lesson_data in Firebase functions.
        v-card(flat color="grey lighten-4").pa-3.pa-md-3.my-6
            v-row
                v-col(cols="12" md="3", v-for="show in shows", :key="show.show + show.season")
                    h2.mb-6 {{show.show}}, S{{show.season}}
                    ul
                        li.mb-6(v-for="n in show.maxEps", :key="show + '-' + n") Episode {{n}}
                            .d-flex
                                v-btn-toggle(rounded)
                                    v-btn(depressed color="primary", :href="`${host}/initLesson?lessonId=${show.show.toLowerCase()}_s0${show.season}_e${padLeft(n)}`", target="_blank") No TTS
                                    v-btn(depressed color="accent", :href="`${host}/initLesson?lessonId=${show.show.toLowerCase()}_s0${show.season}_e${padLeft(n)}&tts=true`", target="_blank") With TTS
</template>

<script>
import { get, sync } from "vuex-pathify";
import Section from "@/components/Lesson/Section.vue";
export default {
  name: "InitLessons",
  components: {
    Section,
  },
  data() {
    return {
      shows: [
        { show: "Avatar", season: 1, maxEps: 20 },
        { show: "Brooklyn Nine Nine", season: 1, maxEps: 23 },
        { show: "Rick and Morty", season: 1, maxEps: 11 },
        { show: "Rick and Morty", season: 3, maxEps: 10 },
      ],
    };
  },

  created() {
    this.isTeacher = true;
    if (
      this.$route.params.id &&
      this.$route.params.id.toLowerCase() === "all"
    ) {
      console.log("init all lessons...");

      fetch(this.host + "/initAllLessons").then(this.$router.push("/"));
    }
  },
  computed: {
    ...get({ isDev: "isDev" }),
    ...sync({ isTeacher: "Lessons/isTeacher" }),
    host() {
      return this.isDev
        ? "http://localhost:5001/sericity-v1/us-central1"
        : "https://us-central1-sericity-v1.cloudfunctions.net";
    },
  },
  methods: {
    padLeft(n) {
      return ("" + n).padStart(2, "0");
    },
  },
};
</script>
