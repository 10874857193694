<template lang="pug">
    .create-lesson
        h1.my-6 Tools for creating Lesson JSON
        Section(title="Create JSON from lines")
            v-textarea(v-model="clozeRaw", outlined placeholder="Type or paste your cloze text, and it will be converted to JSON strings. Remember: ^{word|hint} and %{word|replacement}")
            .buttons.d-flex
                v-btn(:disabled="!clozeRaw", color="primary" depressed @click="clozeJson = linesToJson(clozeRaw)").mr-md-2 Create JSON
                v-btn(:disabled="!clozeJson", color="accent" depressed @click="handleCopy(clozeJson)") Copy
            v-card(v-if="clozeJson && clozeJson.length > 0", flat, color="grey lighten-3").pa-4.mt-5
                p {{clozeJson}}

        Section(title="Create questions and answers from lines starting with 'Q:' or 'A:'")
            v-textarea(v-model="questionsRaw", outlined placeholder="Type or paste your questions and answers, one per line, such as Q: Foo\nA: Bar")
            .buttons.d-flex
                v-btn(:disabled="!questionsRaw", color="primary" depressed @click="questionsJson = questionsToJson(questionsRaw)").mr-md-2 Create JSON
                v-btn(:disabled="!questionsJson", color="accent" depressed @click="handleCopy(questionsJson)") Copy
            v-card(v-if="questionsJson && questionsJson.length > 0", flat, color="grey lighten-3").pa-4.mt-5
                p {{questionsJson}}
        

        
        Section(title="Create Key Expressions JSON")
            v-textarea(v-model="expressionsRaw", outlined placeholder="Type or paste your key expressions, one per line.")
            .buttons.d-flex
                v-btn(:disabled="!expressionsRaw", color="primary" depressed @click="expressionsJson = linesToExpressions(expressionsRaw)").mr-md-2 Create JSON
                v-btn(:disabled="!expressionsJson", color="accent" depressed @click="handleCopy(expressionsJson)") Copy
            v-card(v-if="expressionsJson && expressionsJson.length > 0", flat, color="grey lighten-3").pa-4.mt-5
                p {{expressionsJson}}

        Section(title="Image Prompts Json")
          v-textarea(v-model="imagePromptsFilenamesRaw" outlined, placeholder="Paste in your image prompts filenames and questions. E.g. brooklyn_nine_nine_s01_e01_00001.jpg | What is Jake holding?")
          .buttons.d-flex
              v-btn(:disabled="!imagePromptsFilenamesRaw", color="primary" depressed @click="imagePromptsJson = linesToImagePromptsJson(imagePromptsFilenamesRaw)").mr-md-2 Create JSON
              v-btn(:disabled="!imagePromptsJson", color="accent" depressed @click="handleCopy(imagePromptsJson)") Copy

          v-card(v-if="imagePromptsJson && imagePromptsJson.length > 0", flat, color="grey lighten-3").pa-4.mt-5
            p {{imagePromptsJson}}


</template>

<script>
import { sync } from "vuex-pathify";
import { copyToClipboard, capitalize } from "@/helpers.js";
import Section from "@/components/Lesson/Section.vue";
export default {
  name: "CreateLesson",
  components: { Section },
  data() {
    return {
      questionsRaw: null,
      questionsJson: null,

      clozeRaw: null,
      clozeJson: null,

      expressionsRaw: null,
      expressionsJson: null,

      imagePromptsFilenamesRaw: null,
      imagePromptsJson: null,
    };
  },
  created() {
    this.copyProtection = false;
  },
  beforeUnmount() {
    this.copyProtection = true;
  },
  computed: {
    ...sync({ copyProtection: "copyProtection" }),
  },
  methods: {
    linesToJson(str) {
      const clean = str
        .split('"')
        //eslint-disable-next-line
        .join(`\"`)
        .split("\n")
        .filter(Boolean)
        .map((line) => JSON.stringify(line.trim()) + ",")
        .join("\n");

      return clean.substr(0, clean.length - 1);
    },
    linesToExpressions(str) {
      const isTagged =
        str.toLowerCase().includes("m:") && str.toLowerCase().includes("e:");
      if (isTagged) {
        const terms = str.split("\n\n");

        const tagged = terms.map((term) => {
          const lines = term.split("\n");

          let expression, meaning, example, types;
          lines.forEach((line) => {
            if (line.toLowerCase().startsWith("m:"))
              meaning = capitalize(line.substring(3).trim());
            else if (line.toLowerCase().startsWith("e:"))
              example = capitalize(line.substring(3).trim());
            else if (line.toLowerCase().startsWith("t:"))
              types = line.substring(3).trim().split(",");
            else expression = capitalize(line.trim());
          });

          return { expression, meaning, example, types };
        });

        return JSON.stringify(tagged);
      } else {
        const rawTerms = str.split("\n");
        const cleanTerms = rawTerms
          .filter((term) => term.trim().length > 0)
          .filter(Boolean);

        return cleanTerms
          .map(
            (term) =>
              `{
      "expression": "${term}",
      "meaning": "",
      "example": "",
      "types": []
    },`
          )
          .join("\n");
      }
    },

    questionsToJson(str) {
      const lines = str
        .split("\n")
        .map((line) => line.trim())
        .filter(Boolean);

      const questions = lines
        .filter((line) => line.toLowerCase().startsWith("q:"))
        .map((line) => line.split("q:").join("").split("Q:").join(""));
      const answers = lines
        .filter((line) => line.toLowerCase().startsWith("a:"))
        .map((line) => line.split("a:").join("").split("A:").join(""));

      if (questions.length !== answers.length)
        throw new Error(
          "There was a formatting problem with questions and answers"
        );

      window.debug("questions", questions);

      let res = "";
      for (let i = 0; i < questions.length; i++) {
        res += `{"question":` + JSON.stringify(questions[i].trim()) + ",";
        res += `"answer":` + JSON.stringify(answers[i].trim()) + "}";
        if (i < questions.length - 1) res += ",";
      }

      return "[" + res + "]";
    },

    linesToImagePromptsJson() {
      const lines = this.imagePromptsFilenamesRaw
        .split("\n")
        .filter(Boolean)
        .map((line) => line.trim());

      if (lines && lines.length > 0) {
        let res = "";
        for (let i = 0; i < lines.length; i++) {
          const filename = lines[i].split("|")[0].trim();
          const prompt = lines[i].split("|")[1].trim();
          res += `{\n"image":` + JSON.stringify(filename) + ",\n";
          res += `"prompt":` + JSON.stringify(prompt) + ",\n";
          res += `"isAnimated": false\n}`;
          if (i < lines.length - 1) res += ",\n";
        }

        return "[\n" + res + "\n]";
      } else return "No image prompt content entered yet?";
    },

    handleCopy(str) {
      this.copyProtection = false;
      copyToClipboard(str);
      setTimeout(() => {
        this.copyProtection = true;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  white-space: break-spaces;
}
</style>
